<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/order' }">订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单评价</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <div class="btn-list">
         <el-button
          type="primary"
          size="small"
          style="background: #4781d9; border: 0"
          class="btn-item"
          @click="chainGoodExport"
        >
          <span>导出</span>
          <i class="iconfont icon-daochu" style="font-size:12px"></i>
        </el-button>
      </div>
      <el-form
        ref="form"
        :model="orderForm"
        label-width="100px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="门店名称">
          <el-input
            v-model="orderForm.shopName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称" style="margin-left: 50px">
          <el-input
            v-model="orderForm.orderNum"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否有回复">
        <el-select v-model="orderForm.is_reply" placeholder="是否有回复">
          <el-option label="否" :value="0" />
          <el-option label="是" :value="1" />
        </el-select>
      </el-form-item>
        <el-form-item label="评价时间" style="margin-left: 50px">
          <el-date-picker
            v-model="orderForm.timer"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 查看回复 -->
    <el-dialog
      :visible.sync="visiblereply"
      title="查看回复"
    >
      <div v-if="replyList.length>0" class="reply-box-overflow">
        <div class="reply-box reply-box-top">
          <div class="reply-box-item-top">回复单位</div>
          <div class="reply-box-item-top">回复人</div>
          <div class="reply-box-item-top">回复人电话</div>
          <div class="reply-box-item-top">回复内容</div>
          <div class="reply-box-item-top">回复时间</div>
        </div>
        <div v-for="item in replyList" :key="item.id" class="reply-box reply-box-top">
          <div class="reply-box-item">{{ item.reply_type==1?'客户':item.reply_type==2?'平台':'商家' }}</div>
          <div class="reply-box-item">{{ item.user.nick_name }}</div>
          <div class="reply-box-item">{{ item.user.phone }}</div>
          <div class="reply-box-item">{{ item.content }}</div>
          <div class="reply-box-item">{{ item.created_at }}</div>
        </div>
      </div>
      <div v-else>暂无回复~</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="visiblereply=false">关闭</el-button>
      </div>
    </el-dialog>
     <!-- 回复 -->
     <el-dialog
      :visible.sync="visiblequestion"
      title="回复"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form ref="questionForm" :inline="true" :model="questionForm" size="small" label-width="100px">
        <el-form-item label="内容">
          <el-input v-model="questionForm.content" type="textarea" :rows="5" placeholder="请输入" style="width:400px" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="visiblequestion=false">关闭</el-button>
        <el-button type="primary" size="mini" :loading="loadingBtn" @click="searchFormSubmit">提交</el-button>
      </div>
    </el-dialog>
    <div class="data-table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        type="index"
        :header-cell-style="{ background: '#CD0550', color: '#fff' }"
      >
        <el-table-column prop="id" label="ID"/>
        <el-table-column prop="shop.name" label="门店名称" width="184px"/>
        <el-table-column prop="shop.shop_number" label="门店编号"/>
        <el-table-column prop="good.good_name" label="商品名称"/>
        <el-table-column prop="user.nick_name" label="用户昵称"/>
        <el-table-column label="是否有回复" prop="is_reply">
          <template slot-scope="scope">
            <span v-if="scope.row.is_reply==0">否</span>
            <span v-if="scope.row.is_reply==1">是</span>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="评分"/>
        <el-table-column prop="content" label="内容"/>
        <el-table-column prop="created_at" label="标签">
          <template slot-scope="scope">
            <span v-for="item in scope.row.comment_tag" :key="item.id">{{item+"、"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="评论时间"/>
        <el-table-column label="操作" style="min-width:200px ;">
            <template slot-scope="scope">
              <div style="display:flex;justify-content: center;">
                <el-dropdown style="margin-left:10px;" @visible-change="handleChange(scope.row)" @command="handleCommand">
                  <el-button type="primary" size="mini" style="width: 65px;background-color: transparent;border-color: transparent;">
                    <i class="el-icon-edit-outline" style="color: rgb(60, 166, 240);font-size: 20px;"/>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">查看回复</el-dropdown-item>
                    <el-dropdown-item command="2">回复</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
        </el-table-column>
      </el-table>
      <div class="page-num">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="prev,pager,next, total,sizes,jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderCommen_api ,doOrderCommentReply_api} from "@/api/order.js"
import axios from "axios"
import { httpUrl } from "@/utils/http.js"

export default {
  data() {
    return {
      orderForm: {
        shopName: "",
        orderNum: "",
        is_reply:null,
        timer: "",
      },
      currentPage: 1,
      pageSizes: [10, 20, 50],
      pageSize: 10,
      total: 0,
      tableData: [],
      num: 0,
      active: 1,
      filename: "订单评价",
      currentData:{},
      visiblequestion: false,
      questionForm: {
        content: null
      },
      visiblereply: false,
      replyList: [],
      loadingBtn: false,
    };
  },
  methods: {
    //重置
    emptySearch() {
      this.currentPage = 1
      this.pageSize = 10
      this.orderForm = {
        shopName: "",
        orderNum: "",
        is_reply:null,
        timer: ""
      }
      this.getOrderList()
    },
    //搜索
    search() {
      console.log(this.orderForm.timer)
      this.currentPage = 1
      this.getOrderList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getOrderList()
    },
    //切换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getOrderList()
    },
    //获取订单列表
    async getOrderList() {
      let res = await getOrderCommen_api({
        shop_name: this.orderForm.shopName,
        good_name: this.orderForm.orderNum,
        is_reply:this.orderForm.is_reply,
        page: this.currentPage,
        limit: this.pageSize,
        start_date: this.orderForm.timer?this.orderForm.timer[0]:'',
        end_date: this.orderForm.timer?this.orderForm.timer[1]:''
      })
      this.tableData = res.data.data.list
      this.total = res.data.data.total_count
    },
    handleChange(row) {
      this.currentData = row
    },
    handleCommand(command) {
      if (command == 1) {
        this.replyList = this.currentData.reply || []
        this.visiblereply=true
      } else if (command == 2) {
        this.visiblequestion=true
      }
    },
    async  searchFormSubmit() {
      if (!this.questionForm.content) {
        this.$message.error('请输入回复内容');
        return
      }
      this.loadingBtn = true
      let res = await doOrderCommentReply_api({
        content: this.questionForm.content,
        id: this.currentData.id,
      })
      console.log(res)
      if (res.data.code == 0) {
        this.$message({
          message: '回复成功',
          type: 'success'
        });
        this.loadingBtn = false
        this.getOrderList()
        this.visiblequestion = false
      } else {
        this.loadingBtn = false
        this.$message.error(res.msg);
      }
    },
      //导出
    chainGoodExport() {
      const loading = this.$loading({
          lock: true,
          text: '导出中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

      axios
        .get(
          `${httpUrl}api/v1/order/commentExport?shop_name=${this.orderForm.shopName}&good_name=${this.orderForm.orderNum}&is_reply=${this.orderForm.is_reply}&start_date=${this.orderForm.timer[0]?this.orderForm.timer[0]:''}&end_date=${this.orderForm.timer[1]?this.orderForm.timer[1]:''}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!');
          loading.close()
        })
    }
  },
  created() {
    this.getOrderList()
  }
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
.btn-list {
  display: flex;
  align-items: center;
}
.btn-reply{
  padding: 4px 10px;
  background: #cd0550;
  border-radius: 50px;
  color: #fff;
}
.reply-box{
    display: flex;
    font-size: 12px;
    border: 1px solid #eee;
  }
  .reply-box-top{
    border-bottom: none !important;
  }
  .reply-box-top:last-child{
    border-bottom: 1px solid #eee !important;
  }
  .reply-box-item-top{
    flex: 1;
    padding: 12px 2px;
    height: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #eee;
  }
  .reply-box-item{
    flex: 1;
    padding: 12px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #eee;
  }
  .reply-box-item-top:nth-child(5n),.reply-box-item:nth-child(5n){
    border-right: 0 !important;
  }
  .reply-box-overflow{
    max-height: 500px;
    overflow-y: auto;
  }
</style>